import MainLayout from "@/pcomponents/main/layout/layout";
import MatterListArea from "@/pages/main/math/matter/common/list";

function MatterListPage() {

  return (
    <MainLayout>
      <MatterListArea></MatterListArea>
    </MainLayout>
  );
};
export default MatterListPage;