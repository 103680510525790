import MainLayout from "@/pcomponents/main/layout/layout";
import MatterTypeListArea from "@/pages/main/math/matter_type/common/list";

function MatterTypePage() {

  return (
    <MainLayout>
      <MatterTypeListArea></MatterTypeListArea>
    </MainLayout>
  );
};
export default MatterTypePage;