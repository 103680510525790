import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import SearchArea from "./area/search";

function MatterTypeLayoutListArea(props: any) {
  let myProps = {
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_addon_section: "1",
    s_section_seq: "",

    is_need_count: "",
    is_need_info_arr: "1",
    is_no_limit: "1",
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  //const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [section_info_arr, set_section_info_arr] = useState([]);
  const [change_section_seq, set_change_section_seq] = useState("");

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    form_json_data["sc"] = listAggridRef.current.getListSc();
    setListOpt(form_json_data);
    if (strFunc.is_empty(form_json_data["s_section_seq"])) {
      setInfoArr([]);
      listAggridRef.current.setInfoArr([]);
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type_layout/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          //setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          setTimeout(() => {
            set_idx_num_all_rows();
          }, 100);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let no_saved_row_idx_arr=[];
    let del_select_rows=[];
    for(let i=0;i<selected_row.length;i++){
      let tmp_row=selected_row[i];
      if(strFunc.is_empty(tmp_row["a_seq"])){
        no_saved_row_idx_arr.push(tmp_row["idx_num"]);
      }else{
        del_select_rows.push(tmp_row);
      }
    }
    if(no_saved_row_idx_arr.length>0){
      let all_rows=listAggridRef.current.getRows();
      let remain_rows=[];
      for(let i=0;i<all_rows.length;i++){
        let tmp_row=all_rows[i];
        if(strFunc.str_in_array(tmp_row["idx_num"],no_saved_row_idx_arr)==-1){
          remain_rows.push(all_rows[i]);
        }
      }
      listAggridRef.current.setInfoArr(remain_rows);
      setTimeout(()=>{
        set_idx_num_all_rows();
      },100);
    }
    if(del_select_rows.length===0){
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type_layout/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const set_idx_num_all_rows = () => {
    let all_rows = listAggridRef.current.getRows();
    for (let i = 0; i < all_rows.length; i++) {
      all_rows[i]["idx_num"] = i + 1;
    }
    listAggridRef.current.setInfoArr(all_rows);
  };

  const goSaveSelect = () => {
    let selected_row = listAggridRef.current.getRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    for (let i = 0; i < selected_row.length; i++) {
      selected_row[i]["a_order_num"] = i + 1;
    }
    if (!confirm("저장하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type_layout/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_change_select_section = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (change_section_seq == "") {
      alert("섹션을 선택해주세요.");
      return false;
    }
    if (confirm("선택한 메뉴를 해당섹션으로 이동하시겠습니까?") == false) {
      return false;
    }
    for (let i = 0; i < selected_row.length; i++) {
      selected_row[i]["a_section_seq"] = change_section_seq;
    }
    let form_json_data = {
      data_arr: selected_row,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type_layout/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const add_new_row = () => {
    let tmp_rowData: any = {
      a_id: "",
      a_section_seq: listOpt.s_section_seq,
      a_title: "",
      a_content:"",
      a_order_num: "",
      row_view_section: "",
    };
    if (!strFunc.is_empty(tmp_rowData.a_section_seq)) {
      tmp_rowData["a_order_num"] = infoArr.length + 1;
    } else {
      alert("섹션을 선택해주세요.");
      return false;
    }

    let select_section_info = null;
    for (let i = 0; i < section_info_arr.length; i++) {
      let item = section_info_arr[i];
      if (item["a_seq"] == tmp_rowData.a_section_seq) {
        select_section_info = item;
      }
    }
    if (select_section_info != null) {
      tmp_rowData["section_title"] = select_section_info["a_title"];
    }

    listAggridRef.current.addRowData({
      data: [tmp_rowData],
      focus_key: "a_title",
    });
    setInfoArr([
      ...infoArr,
      ...[tmp_rowData]
    ]);
    setTimeout(() => {
      set_idx_num_all_rows();
    }, 100);
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    return render_str;
  };

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          list={list}
          section_info_arr={section_info_arr}
          set_section_info_arr={set_section_info_arr}
        ></SearchArea>
      </div>
      <div className="text-right">
        <button className="btn btn-dark" onClick={() => { add_new_row(); }} >신규</button>
        <button className="btn btn-blue ml-1" onClick={() => { goSaveSelect(); }} >저장</button>
        <button className="btn btn-red ml-1" onClick={goDelete} >삭제</button>
      </div>
      <div className="text-right mt-1">
        섹션옮기기
        <span className="ml-1">
          <select className="border" value={change_section_seq}
            onChange={(e: any) => {
              set_change_section_seq(e.target.value);
            }} >
            <option value="">선택</option>
            {section_info_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item["a_seq"]}>{item["a_title"]}</option>
              );
            })}
          </select>
        </span>
        <button className="btn btn-blue ml-1" onClick={() => { go_change_select_section(); }} >변경</button>
      </div>
      <div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_grid: true,
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            is_add_rowDrag: true,
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
        {/* <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging> */}
      </div>
    </div>
  );
};
export default MatterTypeLayoutListArea;