import { Suspense } from 'react';
import { RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import router from "@/router/index";
import store from '@/store/store';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

function InitApp() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  );
}

export default InitApp;
