import MainLayout from "@/pcomponents/main/layout/layout";

function ManageMaterPagePgae() {

  return (
    <MainLayout>
      ManageMaterPagePgae
    </MainLayout>
  );
};
export default ManageMaterPagePgae;