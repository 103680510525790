import MainLayout from "@/pcomponents/main/layout/layout";

function MathMainPage() {

  return (
    <MainLayout>
      Test
    </MainLayout>
  );
};
export default MathMainPage;