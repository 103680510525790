import MainLayout from "@/pcomponents/main/layout/layout";
import SectionListArea from "@/pages/main/math/section/common/list";

function SectionManagePage() {

  return (
    <MainLayout>
      <SectionListArea></SectionListArea>
    </MainLayout>
  );
};
export default SectionManagePage;