import MathMainPage from "@/pages/math/main";
import LoginPage from "@/pages/math/login";
const routerArr:object[] = [
  {
    path: "/",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/math",
    element: <MathMainPage></MathMainPage>,
  },
];

export default routerArr;