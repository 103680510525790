import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"math_section",
    list_id:"math_section_list",
    x_column_list_arr:[
      {"key":"a_title","name":"제목","width":"250","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"100","is_show":"1"},
      {"key":"a_stu_season","name":"학기","width":"100","is_show":"1"},
      {"key":"a_order_num","name":"순번","width":"80","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_stu_grade, a_stu_season, a_order_num',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;