import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import strFunc from '@/lib/lyg/string';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    section_info_arr: [],
    set_section_info_arr: (inData: any) => { },
    matter_type_arr: [],
    set_matter_type_arr: (inData: any) => { },
    ...props
  };
  let listOpt=myProps.listOpt;

  const [section_listopt, set_section_listopt] = useState({
    s_stu_grade: "8",
    s_stu_season: "1",
    order_id:"a_order_num",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  let section_info_arr=myProps.section_info_arr;
  let matter_type_arr=myProps.matter_type_arr;

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...section_listopt,
      ...inOptObj
    };
    set_section_listopt(form_json_data);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.set_section_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_matter_type_by_ajax=(inData:any)=>{
    let opt_obj={
      s_section_seq:listOpt.s_section_seq,
      ...inData
    };
    myProps.setListOpt({
      ...listOpt,
      s_section_seq:opt_obj.s_section_seq
    });
    let form_json_data={
      s_section_seq:opt_obj.s_section_seq,
      "order_id":"a_order_num",
      "is_no_limit":"1",
      "is_need_info_arr":"1",
      "is_need_count":""
    };
    if(strFunc.is_empty(form_json_data.s_section_seq)){
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.set_matter_type_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  let section_select_option_arr=[{value:"",text:"전체"}];
  for(let i=0;i<section_info_arr.length;i++){
    let item=section_info_arr[i];
    section_select_option_arr.push({value:item["a_seq"],text:item["a_title"]});
  }

  let matter_type_select_option_arr=[{value:"",text:"전체"}];
  for(let i=0;i<matter_type_arr.length;i++){
    let item=matter_type_arr[i];
    matter_type_select_option_arr.push({value:item["a_seq"],text:item["a_title"]});
  }

  return (
    <div>
      <div>
        학년:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_grade_option_arr
            ]}
            value={section_listopt.s_stu_grade}
            on_change={(inData: any) => {
              list({
                s_stu_grade: inData.value
              });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        학기:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_season_option_arr
            ]}
            value={section_listopt.s_stu_season}
            on_change={(inData: any) => {
              list({
                s_stu_season: inData.value
              });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        섹션:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={section_select_option_arr}
            value={listOpt.s_section_seq}
            on_change={(inData: any) => {
              list_matter_type_by_ajax({ s_section_seq: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        문제:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={matter_type_select_option_arr}
            value={listOpt.s_type_seq}
            on_change={(inData: any) => {
              myProps.list({ now_page:1,s_type_seq: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;