import MainLayout from "@/pcomponents/main/layout/layout";
import MatterTypeLayoutListArea from "@/pages/main/math/matter_type_layout/common/list";

function MatterTypeLayoutPage() {

  return (
    <MainLayout>
      <MatterTypeLayoutListArea></MatterTypeLayoutListArea>
    </MainLayout>
  );
};
export default MatterTypeLayoutPage;