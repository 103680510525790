import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import SectionWritePopup from "@/pages/main/math/section/popup/write";
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';

function SectionListArea(props: any) {
  let myProps = {
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_stu_grade: "8",
    s_stu_season: "1",
    
    is_need_count:"",
    is_need_info_arr:"1",
    is_no_limit:"1",
  });
  const [infoArr, setInfoArr] = useState<any>([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//write
    is_update: false,
    row_data: {},
    title: "수정",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    form_json_data["sc"]=listAggridRef.current.getListSc();
    setListOpt(form_json_data);
    if(strFunc.is_empty(form_json_data["s_stu_grade"])||strFunc.is_empty(form_json_data["s_stu_season"])){
      setInfoArr([]);
      listAggridRef.current.setInfoArr([]);
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          setTimeout(()=>{
            set_idx_num_all_rows();
          },100);
        } else {

        }
      });
  };

  const set_idx_num_all_rows=()=>{
    let all_rows=listAggridRef.current.getRows();
    for(let i=0;i<all_rows.length;i++){
      all_rows[i]["idx_num"]=i+1;
    }
    listAggridRef.current.setInfoArr(all_rows);
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let no_saved_row_idx_arr=[];
    let del_select_rows=[];
    for(let i=0;i<selected_row.length;i++){
      let tmp_row=selected_row[i];
      if(strFunc.is_empty(tmp_row["a_seq"])){
        no_saved_row_idx_arr.push(tmp_row["idx_num"]);
      }else{
        del_select_rows.push(tmp_row);
      }
    }
    if(no_saved_row_idx_arr.length>0){
      let all_rows=listAggridRef.current.getRows();
      let remain_rows=[];
      for(let i=0;i<all_rows.length;i++){
        let tmp_row=all_rows[i];
        if(strFunc.str_in_array(tmp_row["idx_num"],no_saved_row_idx_arr)==-1){
          remain_rows.push(all_rows[i]);
        }
      }
      listAggridRef.current.setInfoArr(remain_rows);
      setTimeout(()=>{
        set_idx_num_all_rows();
      },100);
    }
    if(del_select_rows.length===0){
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: del_select_rows
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goSaveSelect = () => {
    let selected_row = listAggridRef.current.getRows();//listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("저장하시겠습니까?")) {
      return false;
    }
    for(let i=0;i<selected_row.length;i++){
      selected_row[i]["a_order_num"]=i+1;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const add_new_row = () => {
    let tmp_rowData: any = {
      a_seq: "",
      a_stu_grade: listOpt.s_stu_grade,
      a_stu_season: listOpt.s_stu_season,
      a_title: "",
      a_order_num: "",
    };
    if (!strFunc.is_empty(listOpt.s_stu_grade) && !strFunc.is_empty(listOpt.s_stu_season)) {
      tmp_rowData["a_order_num"] = infoArr.length + 1;
    }else{
      alert("학년,학기를 선택해주세요.");
      return false;
    }
    listAggridRef.current.addRowData({
      data: [tmp_rowData],
      focus_key: "a_title",
    });
    setInfoArr([
      ...infoArr,
      ...[tmp_rowData]
    ]);
    setTimeout(()=>{
      set_idx_num_all_rows();
    },100);
  };

  const openWritePopup = (sort: string, is_update: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {
      a_stu_grade: listOpt.s_stu_grade,
      a_stu_season: listOpt.s_stu_season
    };
    if (is_update) {
      pop_title = "수정";
      let selected_row = listAggridRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "is_update": is_update,
      "row_data": tmp_rowData,
      "title": pop_title,
      "sort": sort
    });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "a_stu_grade") {
      render_str = params.data.a_stu_grade;
      let tmp_select_option = strFunc.get_obj_by_key_val_at_obj_arr("value", render_str, MathSearchDataFunc.select_stu_grade_option_arr);
      if (tmp_select_option.length > 0) {
        render_str = tmp_select_option[0].text;
      }
    } else if (key == "a_stu_season") {
      render_str = params.data.a_stu_season;
      let tmp_select_option = strFunc.get_obj_by_key_val_at_obj_arr("value", render_str, MathSearchDataFunc.select_stu_season_option_arr);
      if (tmp_select_option.length > 0) {
        render_str = tmp_select_option[0].text;
      }
    }

    return render_str;
  }, []);

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          list={list}
        ></SearchArea>
      </div>
      <div className="text-right">
        <button className="btn btn-dark" onClick={() => { add_new_row(); }} >신규</button>
        <button className="btn btn-blue ml-1" onClick={() => { goSaveSelect(); }} >저장</button>
        <button className="btn btn-red ml-1" onClick={goDelete} >삭제</button>
      </div>
      <div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_grid: true,
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            is_add_rowDrag: true,
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <SectionWritePopup
              is_update={popupData.is_update} row_data={popupData.row_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></SectionWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default SectionListArea;