import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt=myProps.listOpt;

  return (
    <div>
      <div>
        학년:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_grade_option_arr
            ]}
            value={listOpt.s_stu_grade}
            on_change={(inData: any) => {
              myProps.list({
                s_stu_grade: inData.value
              });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        학기:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_season_option_arr
            ]}
            value={listOpt.s_stu_season}
            on_change={(inData: any) => {
              myProps.list({
                s_stu_season: inData.value
              });
            }}
          ></SelectBoxBar>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;