import {
  createBrowserRouter,
} from "react-router-dom";

import mainRouterArr from "./main/index";
import mathRouterArr from "./math/index";
import testRouterArr from "./test/index";

const router = createBrowserRouter([
  ...mainRouterArr,
  ...mathRouterArr,
  ...testRouterArr,
  {
    path: "*",
    element: <div>404 Not Found!!</div>,
  },
]);

export default router;