//import MainLayout from "@/pcomponents/main/layout/layout";
import Header from "./header";
import Footer from "./footer";
import SNBArea from "./menu/snb";

const MainLayout = (props: any) => {
  let myProps = {
    "isLeftArea": true,
    "isConTitle": true,
    ...props
  };
  let dark_class_name = "light";

  return (
    <div id="main_wrap" className={dark_class_name} >
      <div className="main_body">
        <Header></Header>
        <div className="snb_area_wrap">
          <SNBArea></SNBArea>
        </div>
        <div id="container">
          <div className="container_con">
            {myProps.children}
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default MainLayout;