import MainLayout from "@/pcomponents/main/layout/layout";
import MenuListArea from "@/pages/main/math/menu/common/list";

function ManageMenuPage() {

  return (
    <MainLayout>
      <MenuListArea></MenuListArea>
    </MainLayout>
  );
};
export default ManageMenuPage;