import { useState,useRef,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import style from "./css/style.module.css";
import plani_img from "@/img/logo/plani_logo4_200.png";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import CheckLoginComponent from '@/pcomponents/comp/check/login_check';
import strFunc from '@/lib/lyg/string';

function LoginPage(){
  const navigate = useNavigate();
  const inputschoolRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  
  const [loginData,setLoginData]=useState({
    comp_id:"",
    user_id:"",
    user_pw:"",
    is_save:true,
  });

  useEffect(()=>{
    
    if(inputschoolRef.current){
      //inputschoolRef.current.focus();
    }
    document.title = "로그인";
    setPreID();
  },[]);

  const setPreID=()=>{
    if(localStorage.login_comp_id){
      setLoginData({
        ...loginData,
        comp_id:strFunc.base64Decode(localStorage.login_comp_id),
        user_id:strFunc.base64Decode(localStorage.login_id),
      });
    }
  };

  const onChangInput=(e:any)=>{
    setLoginData({
      ...loginData,
      ...{[e.target.name]:e.target.value}
    });
  };

  const goLogin=()=>{
    if(loginData.comp_id==""){
      alert("학원코드 입력 필요.");
      return false;
    }
    if(loginData.user_id==""){
      alert("아이디 입력 필요.");
      return false;
    }
    if(loginData.user_pw==""){
      alert("비밀번호 입력 필요.");
      return false;
    }

    let login_form_data={
      comp_id:loginData.comp_id,
      id:loginData.user_id,
      pw:loginData.user_pw
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/login',login_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(loginData.is_save){
          localStorage.setItem('login_comp_id', strFunc.base64Encode(loginData.comp_id));
          localStorage.setItem('login_id', strFunc.base64Encode(loginData.user_id));
        }else{
          localStorage.removeItem("login_comp_id");
          localStorage.removeItem("login_id");
        }
        localStorage.setItem('intoyou_token', response.data.data["token_id"]);
        var client_info=response.data.data["client_info"];
        dispatch(setUser(client_info));
        localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
        if(client_info.comp_sort=="main"){
          navigate("/main");
        }else{
          navigate("/math");
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div className={style.login_wrap}>
      <CheckLoginComponent isGoToMainAtLogin={true}></CheckLoginComponent>
      <div className={style.title_logo_wrap}>
        <Link to="/" >
          <img className={style.title_logo} src={plani_img} alt="로고" />
        </Link>
      </div>
      <div className={style.login_box}>
        <div className="relative">
          <input type="text" className={style.login_comp_input} placeholder="학원코드" autoComplete="off"
            value={loginData.comp_id} name="comp_id" onChange={onChangInput} ref={inputschoolRef} />
          {loginData.comp_id!=""&&
            <span className={style.after_fix_input}>학원코드</span>
          }
        </div>
        <div className="relative mt-3">
          <input type="text" className={style.login_id_input} placeholder="아이디" autoComplete="off"
            value={loginData.user_id} name="user_id" onChange={onChangInput} />
          {loginData.user_id!=""&&
            <span className={style.after_fix_input}>아이디</span>
          }
        </div>
        <div className="relative mt-3">
          <input type="password" className={style.login_pw_input} placeholder="비밀번호" autoComplete="off"
            value={loginData.user_pw} name="user_pw" onChange={onChangInput} 
            onKeyUp={(e)=>{if(e.key === 'Enter'){goLogin();}}} />
          {loginData.user_pw!=""&&
            <span className={style.after_fix_input}>비밀번호</span>
          }
        </div>
        <div className="relative pt-2">
          <label className={style.check_login_label}>
            <input type="checkbox" className={style.login_chk_box} checked={loginData.is_save} 
              onChange={()=>{ setLoginData({...loginData,is_save:!loginData.is_save}); }} />
            로그인 상태 유지
          </label>
        </div>
        <div className='pt-5'>
          <button className={style.login_btn} onClick={goLogin} >로그인</button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;