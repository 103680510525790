import SectionWritePage from "@/pages/main/math/section/common/write";

function SectionWritePopup(props:any){
   let myProps={
    closePopup:()=>{},
    is_update:false,
    row_data:{},
    ...props
  };
  
  return (
  <div>
    <SectionWritePage
      is_update={myProps.is_update}
      row_data={myProps.row_data}
      callback={()=>{myProps.callback();myProps.closePopup();}}
    ></SectionWritePage>
  </div>
  );
};
export default SectionWritePopup;