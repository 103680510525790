//import ListAggrid from 'components/common/list/aggrid/list';
import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import AggridCustomFunc from './func/aggrid_func';
import { Grid, GridOptions, ModuleRegistry } from "@ag-grid-community/all-modules";

const ListAggrid = forwardRef((props: any, ref) => {
  const gridRef = useRef(null); // Optional - for accessing Grid's API
  const xColumnArr = { ...props.xColumnArr };
  const [gridOpt, setGridOpt] = useState(
    {
      width: window.innerWidth,
      height: (window.innerHeight / 5) * 3,
      fix_left_num: 0,
      is_grid: false,
      is_able_grid: true,
      is_idx_num: true,
      is_add_checkbox: true,
      is_add_rowDrag: false,
      floatingFilter: true,
      rowSelection: 'multiple',//single,multiple
      rowMultiSelectWithClick: false,
      enableRangeSelection: true,
      enableFillHandle: false,
      enableCellTextSelection: false,
      onGridReady: (params: any) => {

      },
      cellRenderer: (params: any) => {
        var key = params.colDef.field;
        var render_str = params.value;
        return render_str;
      },
      onRowDragEnd: (event: any) => {

      },
      onCellClicked: (params: any) => {
        // console.log(event.colDef.field);
        // console.log(event.data);
      },
      getRowHeight: (params: any) => {
        return null;
        //return params.data.rowHeight;
      },
      rowClassRules: {
        //'rag-green-outer': function(params) { return params.data.year === 2008; },
      },
      onSelectionChanged: (event: any) => {
        //console.log(event);
      },
      ...props.gridOpt
    },
  );
  const list_sc = useRef<any>({});
  const filterInputs = useRef<any>([]);
  const [infoArr, setInfoArr] = useState([...props.infoArr]);
  const [pinnedTopRowData, setPinnedTopRowData] = useState(props.pinnedTopRowData);
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState(props.pinnedBottomRowData);
  let tmp_columnDefs = [...props.columnDefs];
  if (tmp_columnDefs.length == 0) {
    tmp_columnDefs = AggridCustomFunc.getAggridListByXcolumn({
      'x_column_list_arr': xColumnArr.x_column_list_arr,
      'gridOpt': gridOpt,
      'xColumnArr': xColumnArr
    });
  }
  const [columnDefs, setColumnDefs] = useState(tmp_columnDefs);

  const defaultColDef = {
    width: 100,
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    filter: 'agTextColumnFilter',
    floatingFilter: gridOpt.floatingFilter,
    filterParams: {
      filterOptions: ['contains'],
      textMatcher: () => {// ({filter, value, filterText})=>{
        return true;
      }
    },
    ...props.defaultColDef
  };

  let aggridWrapClassName = "ag-theme-alpine w-full mt-1";
  if (gridOpt.is_grid) {
    aggridWrapClassName += " border-2 border-blue-700";
  }

  const setListScData = () => {
    list_sc.current = {};
    for (let i = 0; i < filterInputs.current.length; i++) {
      let headerName = filterInputs.current[i].getAttribute("aria-label").split(" ")[0];
      let field = "";
      for (let j = 0; j < columnDefs.length; j++) {
        if (columnDefs[j].headerName == headerName) {
          field = columnDefs[j].field;
          list_sc.current[field] = filterInputs.current[i].value;
        }
      }
    }

  };
  const setListScInput = () => {
    for (let i = 0; i < filterInputs.current.length; i++) {
      let headerName = filterInputs.current[i].getAttribute("aria-label").split(" ")[0];
      let field = "";
      for (let j = 0; j < columnDefs.length; j++) {
        if (columnDefs[j].headerName == headerName) {
          field = columnDefs[j].field;
          filterInputs.current[i].value = list_sc.current[field];
        }
      }
    }
  };

  useEffect(() => {

  }, []);

  const onSearchOfFloatEnter = (e: any) => {
    setListScData();
    if (e.keyCode == 13) {
      props.list({ "sc": list_sc, "now_page": 1 });
      setTimeout(() => {
        setListScInput();
      }, 100);
    }
  };

  const setListScEvent = useCallback(() => {
    setTimeout(() => {
      filterInputs.current = document.getElementsByClassName("ag-text-field-input");
      for (let i = 0; i < filterInputs.current.length; i++) {
        filterInputs.current[i].addEventListener("keyup", onSearchOfFloatEnter);
      }
    }, 500);
  }, []);

  const getListSc = () => {
    let tmp_list_sc = { ...list_sc.current };
    for (let key in xColumnArr.select_arr) {
      if (tmp_list_sc[key] != undefined) {
        for (let i = 0; i < xColumnArr.select_arr[key].length; i++) {
          let select_row = xColumnArr.select_arr[key][i];
          if (select_row["text"] == tmp_list_sc[key]) {
            tmp_list_sc[key] = select_row["value"];
          }
        }
      }
    }
    return tmp_list_sc;
  };

  useEffect(() => {
    //필터엔터
    setListScEvent();
  }, []);

  const getSelectedRows = useCallback(() => {
    if (gridRef.current == null) {
      return [];
    }
    // api and columnApi on the gridRef object
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return []; }

    return api.getSelectedRows();
  }, []);
  const getRows = useCallback(() => {
    let tmpRowDataArr: any = [];

    if (gridRef.current == null) {
      return [];
    }
    // api and columnApi on the gridRef object
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return []; }

    api.forEachNode(function (rowNode, index) {
      tmpRowDataArr.push(rowNode.data);
    });
    return tmpRowDataArr;
  }, []);
  const getNodeRows = useCallback(() => {
    let tmpRowDataArr: any = [];

    if (gridRef.current == null) {
      return [];
    }
    // api and columnApi on the gridRef object
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return []; }

    api.forEachNode(function (rowNode, index) {
      tmpRowDataArr.push(rowNode);
    });
    return tmpRowDataArr;
  }, []);
  const getSelectedNodeRows = useCallback(() => {
    if (gridRef.current == null) {
      return [];
    }
    // api and columnApi on the gridRef object
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return []; }

    return api.getSelectedNodes();
  }, []);
  const setSelectFalseAll = useCallback(() => {
    if (gridRef.current == null) {
      return [];
    }
    // api and columnApi on the gridRef object
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return []; }

    let nodes = api.getSelectedNodes();
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].setSelected(false);
    }
  }, []);

  const toggleGridEditing = () => {
    AggridCustomFunc.toggleGridEditing({
      "gridRef": gridRef,
      "xColumnArr": xColumnArr,
      "gridOpt": gridOpt,
      "setGridOpt": setGridOpt,
      "columnDefs": columnDefs,
      "setColumnDefs": setColumnDefs,
    });
  };
  const addRowData = (opt_obj: any) => {
    AggridCustomFunc.addRowData({
      "data": opt_obj["data"],//[{},{}]
      "focus_key": opt_obj["focus_key"],
      "gridRef": gridRef,
    });
  };
  const updateRowData = (opt_obj: any) => {
    if (gridRef.current == null) {
      return false;
    }
    // api and columnApi on the gridRef object
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return false;; }
    api.applyTransaction({ add: opt_obj["data"] });
  };
  const getGridRef = () => {
    return gridRef;
  };
  const setFocusedCell = (idx: number, key: string) => {
    if (gridRef.current == null) {
      return false;
    }
    const { api } = gridRef.current as GridOptions;
    if (api == null ) { return false; }
    api.setFocusedCell(idx, key);
  };
  const setHeight = (height: any) => {
    setGridOpt({
      ...gridOpt,
      height: height
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInfoArr, setColumnDefs, getSelectedRows, toggleGridEditing,
    addRowData, updateRowData, getRows, getGridRef, getListSc,
    getSelectedNodeRows, setSelectFalseAll,
    getNodeRows, setPinnedTopRowData, setPinnedBottomRowData,
    setFocusedCell, setHeight
  }));

  return (
    <div>
      <div className={aggridWrapClassName} style={{ height: gridOpt.height }}>
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API

          rowData={infoArr} // Row Data for Rows

          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties

          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection={gridOpt.rowSelection} // Options - allows click selection of rows
          rowMultiSelectWithClick={gridOpt.rowMultiSelectWithClick}
          rowClassRules={gridOpt.rowClassRules}

          onGridReady={gridOpt.onGridReady}
          onCellClicked={gridOpt.onCellClicked} // Optional - registering for Grid Event
          rowDragManaged={true}
          onRowDragEnd={gridOpt.onRowDragEnd}
          getRowHeight={gridOpt.getRowHeight}
          onSelectionChanged={gridOpt.onSelectionChanged}
          pinnedTopRowData={pinnedTopRowData}
          pinnedBottomRowData={pinnedBottomRowData}
        />
      </div>
    </div>
  );
});

ListAggrid.defaultProps = {
  infoArr: [],
  columnDefs: [],
  xColumnArr: {
    x_column_list_arr: {},//{"a_ymd":{"name":"board_ymd","width":"100","is_show":"1"}}
    x_column_list_orig_arr: {},
    pri_col_arr: [],//["a_seq"]
    select_arr: {},//{"a_is_allow":[{"value":"2","text":"미허용"}]}
    view_col_arr: [],// ['key1','key2']
    number_col_arr: [],// ['key1','key2']
    tel_col_arr: [],// ['key1','key2']
    busin_col_arr: [],// ['key1','key2']
    law_num_col_arr: [],// ['key1','key2']
    date_col_arr: [],// ['key1','key2']
    password_col_arr: [],// ['key1','key2']
    checkbox_col_arr: [],// ['key1','key2']
  },
  defaultColDef: {

  },
  gridOpt:
  {

  },
  list: () => { },
  pinnedTopRowData: [],
  pinnedBottomRowData: [],
}

export default ListAggrid;
